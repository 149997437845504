* {
  font-family: @font-family-sans-serif;
  font-variant-ligatures: none;
}

.CodeMirror,
.CodeMirror * {
  font-family: monospace;
}

@keyframes offline-rotation {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -o-transform: rotate(359deg);
  }
}

a {
  cursor: pointer;
}

a:focus,
a:active,
button::-moz-focus-inner,
input[type='reset']::-moz-focus-inner,
input[type='button']::-moz-focus-inner,
input[type='submit']::-moz-focus-inner,
select::-moz-focus-inner,
input[type='file'] > input[type='button']::-moz-focus-inner {
  border: 0;
  outline: 0;
}

.alert {
  word-wrap: break-word !important;
  i.fa {
    margin-right: 7px;
  }
}

.btn-group-select {
  width: 100%;
  .btn {
    color: @gray;
    font-weight: 400;
    font-size: 13px;
    padding: 6px 12px 6px;
    background-color: #fff;
    &.active {
      box-shadow: none;
      background-color: #eee;
      color: @brand-primary;
      border-color: @gray-light;
      font-weight: 600;
    }
  }
}
.label-with-hint {
  display: inline-block;
  position: relative;
  .label-with-hint-icon {
    position: absolute;
    top: 2px;
    right: -17px;
    color: @gray-lighter;
    font-size: 15px;
  }
}

.btn {
  &.btn-md {
    height: 41px;
    padding: @padding-large-vertical @padding-large-horizontal;
  }
  &[data-action='submit'] {
    -webkit-transition: 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275) padding !important;
    -moz-transition: 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275) padding !important;
    -ms-transition: 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275) padding !important;
    -o-transition: 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275) padding !important;
    transition: 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275) padding !important;
    &.loading {
      position: relative;
      &::after {
        -webkit-animation: offline-rotation 0.7s linear infinite;
        animation: offline-rotation 0.7s linear infinite;
        -webkit-backface-visibility: hidden;
        border-radius: 50%;
        content: ' ';
        display: block;
        position: absolute;
        right: 1em;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 1em;
        width: 1em;
        border: 2px solid rgba(0, 0, 0, 0);
        border-top-color: @gray-base;
        border-left-color: @gray-base;
        opacity: 1;
      }
      padding-right: @padding-base-horizontal * 3;
    }
    padding-right: @padding-base-horizontal;
  }
}

.btn-md {
  &[data-action='submit'] {
    &.loading {
      padding-right: @padding-large-horizontal * 2.4;
    }
    padding-right: @padding-large-horizontal;
  }
}

.btn-lg {
  &[data-action='submit'] {
    &.loading {
      padding-right: @padding-large-horizontal*1.4 * 2;
    }
    padding-right: @padding-large-horizontal*1.4;
  }
}

.btn-sm {
  &[data-action='submit'] {
    &.loading {
      padding-right: @padding-small-horizontal * 3;
    }
    padding-right: @padding-small-horizontal;
  }
}

.btn-xs {
  &[data-action='submit'] {
    &.loading {
      padding-right: @padding-xs-horizontal * 6;
    }
    padding-right: @padding-xs-horizontal;
  }
}

.btn-default {
  &[data-action='submit'] {
    &.loading {
      &::after {
        border-top-color: @btn-default-color;
        border-left-color: @btn-default-color;
      }
    }
  }
}

.btn-primary {
  &[data-action='submit'] {
    &.loading {
      &::after {
        border-top-color: @btn-primary-color;
        border-left-color: @btn-primary-color;
      }
    }
  }
}

.btn-success {
  &[data-action='submit'] {
    &.loading {
      &::after {
        border-top-color: @btn-success-color;
        border-left-color: @btn-success-color;
      }
    }
  }
}

.btn-info {
  &:hover {
    background-color: darken(@brand-info, 5%);
  }
  &[data-action='submit'] {
    &.loading {
      &::after {
        border-top-color: @btn-info-color;
        border-left-color: @btn-info-color;
      }
    }
  }
}

.btn-warning {
  &[data-action='submit'] {
    &.loading {
      &::after {
        border-top-color: @btn-warning-color;
        border-left-color: @btn-warning-color;
      }
    }
  }
}

.btn-danger {
  &[data-action='submit'] {
    &.loading {
      &::after {
        border-top-color: @btn-danger-color;
        border-left-color: @btn-danger-color;
      }
    }
  }
}

input[type='checkbox'] {
  -webkit-appearance: checkbox !important;
  -moz-appearance: checkbox !important;
}

input[type='checkbox']:hover {
  -webkit-appearance: checkbox !important;
  -moz-appearance: checkbox !important;
}

input:focus {
  outline: none !important;
}

div[data-notify='container'],
.errors-notify,
.success-notify {
  font-size: @font-size-base;
  font-family: @font-family-base;
  padding: @alert-padding;
  -webkit-border-radius: @alert-border-radius;
  -moz-border-radius: @alert-border-radius;
  border-radius: @alert-border-radius;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
  &.alert-dismissible {
    padding-right: 2.5em;
  }
  [data-notify='title'] {
    &:empty {
      display: none;
    }
  }
  samp,
  code {
    font-size: 0.9em;
    padding: 0.3em;
  }
}

.modal-body {
  div[data-notify='container'] {
    box-shadow: none;
  }
}

.xplenty-icons {
  display: none;
  width: 0;
  height: 0;
}

.tooltip {
  .tooltip-arrow {
    border: 1px solid transparent;
    background-color: transparent;
  }
  .tooltip-inner {
    border: 1px solid #fff;
    font-size: 14px;
    font-weight: 400;
    padding: 7px 12px;
    .box-shadow(0 0px 12px rgba(0, 0, 0, 0.175));
  }
  &.tooltip-error {
    .tooltip-arrow {
      border-top-color: lighten(@brand-warning, 45%);
    }
    .tooltip-inner {
      background-color: lighten(@brand-warning, 45%);
      border-color: lighten(@brand-warning, 45%);
      color: darken(@brand-warning, 10%);
      font-weight: 400;
      padding: 5px 10px;
    }
  }
}

.dashboard {
  width: 1496px;
  height: 1070px;
  background-image: url('../../assets/img/dashboard.png');
  background-repeat: no-repeat;
  background-position: 20px 20px;
}

div[ui-sref] {
  cursor: pointer;
}

.app-container-fluid {
  width: 100%;
}

.container-max {
  margin: 0;
}

.highlighted-text {
  background: rgba(244, 114, 0, 0.5);
}

[data-notify='container'] {
  padding: 2px 5px;
  font-size: 12px;
}

.fade {
  opacity: 0;
  -webkit-transition: opacity 150ms linear;
  -moz-transition: opacity 150ms linear;
  -o-transition: opacity 150ms linear;
  transition: opacity 150ms linear;
  &.in {
    opacity: 1 !important;
  }
}

.btn-group-default {
  .btn {
    color: @text-color;
    &:active,
    &.active {
      -webkit-box-shadow: none;
      box-shadow: none;
      color: @brand-primary;
    }
  }
}

.page-container {
  padding: 50px;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.form-control-search {
  input {
    padding-left: 30px;
  }
  .form-control-feedback {
    top: 0;
    left: 0;
  }
}

.nav-tabs {
  li {
    a {
      cursor: pointer;
      border: 1px solid transparent;
      border-bottom: 3px solid transparent;
      color: @gray-light;
    }
    &.active > a {
      &,
      &:hover,
      &:focus {
        border: 1px solid transparent;
        color: @brand-primary;
        border-bottom: 3px solid @brand-primary;
        cursor: default;
      }
    }
  }
}

.panel {
  .panel-heading,
  .panel-body,
  .panel-footer {
    border: 0 none;
  }
}

/*
    MIXINS
*/

.transform(@transform) {
  -ms-transform: @transform;
  transform: @transform;
}

.tab-focus() {
  outline: 0;
}

.ssh-key-fingerprint {
  display: block;
  font-weight: normal;
  color: #999;
  border: none;
  background-color: transparent;
  padding: 3px 0 0 0;
  margin-bottom: 3px;
}

.date-tooltip {
  border-bottom: 1px dashed @gray-dark;
}

.offline-ui {
  bottom: initial !important;
}

.offline-ui.offline-ui-up {
  display: none;
}

a[target='_blank'].new-window:after {
  color: #3f3f3f;
  font:
    normal normal normal 12px/1 FontAwesome,
    sans-serif;
  font-style: normal;
  font-weight: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  text-decoration: none;
  display: inline-block;
  content: '\f08e';
  margin: 0 2px 0 5px;
  vertical-align: text-top;
}

.notify.animated {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
}

span.left-separator {
  &:before {
    color: @gray-light;
    padding-left: 4px;
    margin-right: 4px;
    content: '•';
  }
}

/* This style is used by HTML declared in en.json, 
 rendered via translate={} of some elements. */
.linkOpensNewTab {
  cursor: alias;
}

.horizontal-line {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid @gray-lighter;
}

xp-input {
  width: 100%;

  &.disabled {
    background-color: @gray-lighter;
    cursor: not-allowed;
    pointer-events: none;
  }

  input[disabled] {
    background-color: @gray-lighter;
    cursor: not-allowed;
    pointer-events: none;
  }

  &.input-sm {
    padding: 1px 10px;
  }
}

xp-form-validation,
.connection-form {
  .errors-notify,
  .success-notify {
    display: inline-block;
    position: absolute;
    transition: all 0.5s ease-in-out 0s;
    z-index: 5000;
    bottom: 100px;
    left: 0;
    right: 0;
    margin: 0 50px;
    height: 0;
    padding: 0;

    .mdc-snackbar__surface {
      height: 0;
      padding: 0;
      transition: all 0.5s ease-in-out 0s;
    }

    &.show-notify {
      height: auto;

      .mdc-snackbar__surface {
        height: auto;
        padding: 1em;
        opacity: 1;
        min-width: auto;
        max-width: 100%;
        transform: none;
      }
    }
  }
}

.panel {
  border-radius: 10px !important;

  > .panel-heading {
    background: rgba(208, 213, 221, 0.2);
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #3d4651;
  }
}

.modal-icon-use-background {
  pointer-events: none;
  fill: #3d4651;
}

.modal-icon-use {
  transform: scale(0.6) translate(10px, 10px);
  fill: transparent;
  stroke: #ffffff;
  stroke-width: 1.5;
  stroke-linecap: round;
  stroke-linejoin: round;

  &.small {
    transform: scale(0.6) translate(8px, 8px);
  }

  &.big {
    transform: scale(0.6) translate(14px, 14px);
  }
}

component-icon {
  svg {
    stroke: #ffffff;
    fill: transparent;
  }
}

.common-icon {
  width: 30px;
  height: 30px;

  &.small {
    width: 24px;
    height: 24px;
  }

  &.big {
    width: 40px;
    height: 40px;
  }
}

.alert-warning {
  color: @brand-warning;
  background: @brand-warning-background;
}
