.rest-api-source-editor {
  .CodeMirror-gutters {
    width: 29px !important;
  }
}

.rest-api-destination-editor {
  hr {
    margin: 10px 0;
  }

  h4 {
    &.middle-header {
      margin-top: 30px;
    }
  }
}
