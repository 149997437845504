.generic-list {
  width: 100%;
  height: 100%;
  max-width: 100%;
  padding: 0 30px;

  @media (max-width: @screen-sm) {
    margin: 0;
    max-width: 100%;
    padding: 5px;
  }

  &.workspaces-generic-list {
    max-width: @screen-md;
  }

  .generic-list-header {
    margin: 30px 0;
    overflow: visible;
    height: 52px;
    position: relative;
    display: flex;
    justify-content: space-between;

    @media (max-width: @screen-sm) {
      flex-flow: column;
      width: 100%;
      padding: 20px;
      height: auto;
      margin: 0 0 10px 0;
    }

    .generic-list-sortby {
      float: right;
      margin-right: 15px;
      padding: 0;
      label {
        margin-right: 15px;
      }
    }
    .generic-list-new-button {
      float: right;
      font-size: @font-size-large*0.9;
      font-weight: bold;
    }
  }
  .generic-list-sortby {
    padding: 5px 0;
    .generic-list-sortby-select {
      width: 160px !important;
      .btn {
        box-shadow: none;
        #gradient > .vertical(#ffffff, #ffffff);
        text-decoration: none;
        &:focus {
          outline: none !important;
        }
        border: 1px solid @gray-lighter;
        border-radius: 5px;
      }
      &:focus {
        outline: none !important;
      }
    }
  }

  .generic-list-header-title {
    width: 100%;
    background: #f9fafb;
    padding: 10px 40px;
    text-transform: capitalize;
    border: 1px solid #eceef0;
    border-bottom: none;
    font-weight: 500;

    @media (max-width: @screen-sm) {
      text-align: center;
    }

    &.second-title {
      margin-top: 50px;
    }
  }

  .generic-list-headers {
    text-transform: uppercase;
    font-size: 12px;
    background: #f9fafb;
    border: 1px solid #eceef0;
    padding: 10px 0;

    i {
      &.fa {
        margin-left: 5px;
      }

      &.active {
        color: @brand-primary !important;
      }
    }
    .sorting {
      cursor: pointer;
      margin-right: 5px;
      &:hover {
        text-decoration: underline;
      }
    }
    &.members {
      padding-bottom: 5px;
      display: grid;
      grid-template-columns: 60px 1fr 200px 100px 100px 100px 100px 40px;

      @media (max-width: @screen-sm) {
        grid-template-columns: 100px 1fr 200px 40px;
      }

      .advanced-role {
        text-align: center;

        .fa {
          margin-left: 5px;
        }
      }
    }

    &.packages {
      padding-left: 40px;
      padding-bottom: 5px;
      display: grid;
      grid-template-columns: [package-name] 1fr 1fr [workspace] 1fr [owner] 1fr [date] 1fr [run-job] 100px [dropdown] 80px;

      @media (max-width: @screen-sm) {
        grid-template-columns: [package-name] 1fr [date] 1fr [run-job] 1fr [dropdown] 40px;
        padding-left: 10px;
      }

      div {
        text-transform: uppercase;
        font-size: 12px;
        &.name {
          grid-column: 1 / span 2;

          @media (max-width: @screen-sm) {
            text-align: center;
            grid-column: 1 / span 1;
          }
        }

        &.owner {
          @media (max-width: @screen-sm) {
            display: none;
          }
        }

        &.date,
        &.run-job {
          @media (max-width: @screen-sm) {
            text-align: center;
          }
        }
      }
    }

    &.workspaces {
      display: none;
      .id {
        width: 140px;
      }
      .status {
        width: 250px;
      }
    }
    &.connections {
      padding-left: 40px;
      padding-bottom: 5px;
      display: grid;
      grid-template-columns: [connection-name] 1fr 1fr [type] 1fr [owner] 1fr [date] 1fr [dropdown] 80px;

      @media (max-width: @screen-sm) {
        grid-template-columns: [connection-name] 1fr [type] 1fr [date] 1fr [dropdown] 40px;
        padding-left: 10px;
      }

      div {
        text-transform: uppercase;
        font-size: 12px;
        &.name {
          grid-column: 1 / span 2;

          @media (max-width: @screen-sm) {
            grid-column: 1 / span 1;
          }
        }

        @media (max-width: @screen-sm) {
          &.owner {
            display: none;
          }

          &.name,
          &.type,
          &.date {
            text-align: center;
          }
        }
      }
    }
    &.schedules {
      display: grid;
      grid-template-columns: [schedule-name] 1fr 1fr [owner] 1fr [frequency] 1fr [last-run] 1fr [next-due] 100px [dropdown] 80px;
      padding-bottom: 5px;

      @media (max-width: @screen-sm) {
        grid-template-columns: [schedule-name] 1fr [frequency] 1fr [last-run] 1fr [next-due] 1fr [dropdown] 40px;
        padding-left: 10px;
      }

      div {
        text-transform: uppercase;
        font-size: 12px;
        &.name {
          grid-column: 1 / span 2;
          padding-left: 40px;

          @media (max-width: @screen-sm) {
            grid-column: 1 / span 1;
            padding-left: 10px;
            text-align: center;
          }
        }

        &.owner {
          @media (max-width: @screen-sm) {
            display: none;
          }
        }

        &.last-run,
        &.next-run,
        &.repeat {
          @media (max-width: @screen-sm) {
            display: none;
          }
        }
      }
    }
    &.jobs {
      display: grid;
      grid-template-columns: [job] 180px [package] 1fr [cluster] 200px [runstime] 240px [status] 130px [details] 80px [dropdown] 40px [end];
      padding-left: 40px;
      padding-bottom: 5px;

      @media (max-width: @screen-sm) {
        grid-template-columns: [package] 1fr [runstime] 100px [status] 100px [details] 60px [end];
        padding-left: 5px;
        max-width: 100vw;
        overflow: hidden;

        .cluster,
        .job,
        .execution_time {
          display: none;
        }

        .package,
        .runtime,
        .status {
          text-align: center;
        }

        :last-child {
          &:not(.fa) {
            right: -13px;
          }
        }
      }
      .job,
      .package,
      .cluster,
      .runtime,
      .status,
      .details {
        border: none;
      }
      :last-child {
        &:not(.fa) {
          position: relative;
        }
      }
      .package,
      .cluster {
        strong {
          padding-right: 15px;
        }
      }
    }
    .package {
      padding: 0 !important;
    }
  }
  .generic-list-body {
    border: 1px solid #eceef0;

    &.schedules {
      border-bottom: 1px solid #cccccc;
    }

    &.clusters {
      border: none;
    }
  }
  .generic-list-empty {
    border-radius: 5px;
    padding: 5px;
    margin: 0;
    background-color: inherit;
    border: 1px solid darken(@gray-lighter, 3%);
    box-shadow: 0 0 1px 1px @gray-lighter;

    .lead {
      margin: 10px 0 5px 0;
      font-weight: 700;
    }

    .empty-list-icon {
      width: 40px;
      height: 40px;
      margin-bottom: 10px;
    }
    .generic-list-empty-search {
      margin-top: 20px;
      padding: 30px 80px 45px 80px;
    }

    .about-box {
      margin-top: 0;
      padding: 30px 40px 30px 40px;

      h4 {
        text-transform: uppercase;
        line-height: 44px;
        font-weight: 800;
      }
      .row {
        display: flex;
      }
      .left-pane {
        padding-right: 45px;
        hr {
          border-color: @gray-light;
          margin-bottom: 30px;
        }
        dl {
          font-size: 16px;
        }
        dd {
          margin-bottom: 15px;
        }
        dd {
          opacity: 0.75;
        }
      }
      .right-pane {
        display: flex;
        justify-content: center;
        align-items: center;
        &.connections {
          img {
            max-width: 498px;
          }
        }
        &.packages {
          img {
            max-width: 648px;
          }
        }
        &.jobs {
          img {
            max-width: 605px;
          }
        }
        &.clusters {
          img {
            max-width: 439px;
          }
        }
        &.schedules {
          img {
            max-width: 471px;
          }
        }
        &.workspaces {
          img {
            max-width: 471px;
          }
        }
      }
      .icon-container {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid darken(@gray-lighter, 3%);
        .box-shadow(0 0 1px 1px @gray-lighter);
        margin-right: 18px;
        .icon {
          width: 22px;
          height: 22px;
          margin-bottom: 0;
        }
      }
    }
  }
  .generic-list-item(100%);
  .generic-list-loader {
    width: 100%;
    text-align: center;
    padding: 30px 0;
  }
  .col-sm-6,
  .col-sm-4,
  .col-sm-2 {
    padding: 0;
  }
}

.workspaces-header {
  display: flex;
  flex-flow: row;
  width: max-content;
  background-color: #f6f6f6;
  border-radius: 10px;
  color: @gray;
  margin-left: 0;
  margin-top: 20px;
  align-items: center;
  position: relative;
  padding-left: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);

  .workspaces-back {
    margin-left: 10px;
    cursor: pointer;
    color: @gray;
  }

  .workspace-name {
    margin-left: 20px;
    font-size: 16px;
    color: #3f3f3f;
    background-color: white;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 10px;
    font-weight: 600;
  }
}

.generic-list-error {
  padding: 50px;
  display: flex;
  justify-content: center;
  font-size: 22px;
  text-align: center;
}
