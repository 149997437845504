components-list {
  height: 100%;
  display: block;
  overflow: auto;
}

.components-list {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  padding: 20px;

  .components-list-sources,
  .components-list-transformations,
  .components-list-destinations,
  .components-list-workflows {
    min-width: 325px;
    background: #f5f7f9;
    border-radius: 6px;
    padding: 10px;
    margin: 0 8px;
  }

  .title-container {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    svg {
      margin-left: 23px;
      margin-top: 12px;
    }

    .components-list-item-icon-background {
      position: absolute;
      opacity: 0.1;
      pointer-events: none;
      background: #3d4651;
      border-radius: 10px;
      width: 40px;
      height: 40px;
      left: 13px;
    }

    .components-list-item-icon {
      transform: scale(0.6);
      pointer-events: none;
      fill: transparent;
      stroke: #3d4651;
      stroke-width: 1;
      stroke-linecap: round;
      stroke-linejoin: round;
      transition: all 0.2s ease-in;
    }
  }

  .lead {
    font-weight: 300;
    display: block;
    margin-bottom: 0;
    padding-left: 15px;
  }

  .list-group {
    .list-group-item {
      display: flex;
      flex-direction: row;
      cursor: pointer;
      border-bottom: 1px solid transparent;
      align-items: center;
      background: #ffffff;
      margin: 8px;
      border-radius: 10px;
      padding: 5px;
      transition: all 0.2s ease-in;
      position: relative;

      &.disabled {
        opacity: 0.8;
        filter: grayscale();
      }

      &:hover {
        background: @brand-primary;

        .item-name {
          color: #ffffff;
        }

        .designer-component-icon {
          stroke: #ffffff;

          &.icon-file-mover {
            fill: #ffffff;
          }
        }
      }

      .badge {
        background: #177af0;
        color: #ffffff;
        font-size: 8px;
        position: absolute;
        top: -5px;
        left: -10px;
      }

      .designer-components-list-icon-background {
        opacity: 0.1;
        pointer-events: none;
        background: @brand-primary;
        width: 40px;
        height: 40px;
        border-radius: 10px;
        position: absolute;
      }

      .item-name {
        transition: all 0.2s ease-in;
        padding-left: 20px;
        line-height: 40px;
        font-family: 'Arial', sans-serif;
      }
    }
  }
}

components-list {
  .modal-body {
    overflow: auto !important;
  }
}
