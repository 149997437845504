.auth-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background: @brand-primary;

  .container {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  .panels-container {
    display: flex;
    flex-flow: row;
    background-color: #fff;
    border-radius: 6px;
    z-index: 2;
    box-shadow: 0 0px 15px 4px rgba(0, 0, 0, 0.1);
  }

  .panel {
    display: flex;
    flex-flow: column;
    z-index: 2;
    width: 530px;
    padding: 20px 30px 30px 30px;
    margin-bottom: 0;
    box-shadow: none;
    border: none;

    &.referral {
      padding: 0;
      border-right: 1px solid #e6e6e6;
      position: relative;
      overflow: hidden;
      height: 100%;

      .integrateio-notification {
        width: 100%;
        height: 487px;

        .ticker-wrap {
          display: none;
        }
      }
    }

    .form-group {
      margin: 15px 0;
    }
    .alert-danger {
      border: 0 none;
      background-color: @brand-warning;
      color: #fff;
    }
    input {
      border: 1px solid @gray-lighter;
      font-size: 14px;
      font-weight: 300;
      border-radius: 8px;
      padding: @padding-large-horizontal;
      height: 100%;
      // line-height: 0;
      &:focus,
      &:active {
        box-shadow: none;
        outline: none;
        border: 1px solid @brand-primary;
      }
      &:hover {
        border-color: @brand-primary;
      }
    }
    button {
      &.btn {
        text-transform: uppercase;
        letter-spacing: 1px;
      }

      &.btn-primary[disabled] {
        background-color: @brand-primary;
        border-color: transparent;
      }
    }
    .panel-links {
      font-size: 15px;
      line-height: 17px;
      text-align: center;
      span {
        color: lighten(@gray-base, 30%);
      }
      a {
        color: lighten(@gray-base, 10%);
        font-weight: 600;
        text-decoration: none;
        border-bottom: 1px solid fade(@gray-base, 20%);
        &:hover {
          border-bottom: 1px solid @gray-base;
        }
      }
    }
    .panel-header {
      margin-top: 15px;
      min-height: 50px;
      padding: 0 10px;
      position: relative;

      .panel-title {
        font-size: 22px;
        line-height: @line-height-large;
        transform: translateY(-50%);
        position: absolute;
        top: 50%;
        left: 110px;
        width: 350px;
      }
      .panel-logo {
        float: left;
        margin-right: 20px;
        width: 80px;
      }
    }
    .panel-footer {
      border-radius: @border-radius-large;
      margin: 0 15px 5px 15px;
      padding: 17px 22px;
    }
  }

  .referral-image {
    background-image: url('../../../assets/img/referral-campaign.png');
    background-size: cover;
    background-position: 100%;
    background-repeat: no-repeat;
    height: 260px;
  }

  .referral-title {
    text-align: center;
    margin-bottom: 20px;
  }

  .referral-text {
    text-align: center;
    font-size: 16px;
  }

  .referral-text-container {
    padding: 10px;
  }

  .referral-link {
    display: flex;
    justify-content: center;

    .referral-button {
      background-color: #f1315b;
      border: none;
      padding: 15px 70px;
      color: #fff;
      margin-top: 20px;
      font-size: 18px;
      margin-bottom: 20px;
    }
  }
}

sign-up,
app-confirmation,
members-invitation,
forgot-password,
reset-password {
  z-index: 2;
}
