schema-mapping-hubspot-collection {
  .hubspot-fields-collection {
    .field_name-cell,
    .column_name-cell {
      width: auto;
    }

    .mat-mdc-header-cell {
      &:nth-child(3) {
        width: 378px;
      }
    }
  }
}

.schema-mapping-hubspot {
  hr {
    margin: 10px 0;
  }

  .middle-header {
    margin-top: 30px;

    .optional-text {
      font-size: 12px;
      color: @gray-light;
    }
  }
}

.mat-cell.no-items-found-text {
  text-align: center;
  padding: 20px !important;
  margin: 10px;
  width: 100%;

  .link {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }
}

.fields-collection-wrapper {
  border: 1px solid @gray-light;
  border-radius: 5px;
  overflow-x: visible !important;
  margin-bottom: 20px;
  background: #ffffff;

  .mat-mdc-table {
    background: #ffffff;
    border: 1px solid @gray-light;
    border-right-style: none;
    border-left-style: none;
  }

  .fa-exclamation-circle {
    margin-left: 5px;
  }

  .group_type-cell {
    width: 200px;
  }

  .is_bson-cell {
    width: 70px;
  }

  .association_type_id-cell,
  .mat-column-association_type_id {
    width: 250px;
    max-width: 300px;
  }

  .association_type_category-cell,
  .mat-column-association_type_category {
    width: 200px;
    max-width: 250px;
    min-width: 200px;
  }

  .function_name-cell,
  .field_name-cell,
  .column_name-cell,
  .field_type-cell {
    width: 150px;
    max-width: 200px;
  }

  .relationship_name-cell {
    width: 150px;
    max-width: 150px;
  }

  .is_merge_key-cell,
  .bson-cell {
    width: 32px;
  }
}

group-by-collection {
  .fields-collection-wrapper {
    .field_name-cell {
      width: auto;
    }
  }
}

schema-mapping-database-collection,
schema-mapping-rest-api-collection {
  .fields-collection-wrapper {
    .field_name-cell,
    .column_name-cell {
      width: auto;
    }
  }
}

.fields-collection-header {
  padding: 32px 32px 0 32px;
  display: flex;
  .fields-collection-search {
    width: 50%;
    flex: 1 auto;
    position: relative;
    min-height: 40px;
    .fields-collection-search-input {
      border: 1px solid @gray-light;
      padding-right: 24px;
    }
    .fields-collection-search-info {
      font-size: 12px;
      padding: 5px;
      text-align: right;
      span {
        display: inline-block;
        padding-right: 10px;
      }
      .btn-link {
        padding: 4px 2px;
        i {
          color: @gray;
          &.fa-dot-circle-o {
            color: @brand-primary;
          }
        }
      }
    }
    .fields-collection-search-hint {
      position: absolute;
      top: 8px;
      right: -18px;
      color: @gray-light;
    }
    .fields-collection-search-clear {
      position: absolute;
      z-index: 1;
      top: 8px;
      right: 8px;
      color: @gray-light;
      cursor: pointer;
    }
  }
  .fields-collection-autofill {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: baseline;

    .btn {
      height: 28px;
      border: 1px solid @gray-light;
    }
    .fa {
      margin-left: 5px;
      color: @gray-lighter;

      &:hover {
        color: @gray-light;
      }
    }
    .btn-group {
      float: right;
    }
  }
  .tooltip {
    .tooltip-inner {
      min-width: 280px;
      text-align: left;
    }
  }
}

.fields-collection-table {
  display: table; // border-bottom: 1px solid @gray-lighter;
  width: 100%;
  .ui-sortable-placeholder {
  }
}

.fields-collection-table-group {
  border-top: 1px solid @gray-lighter;
  display: table-row-group;
  .ui-sortable-placeholder {
    width: 100%;
    height: 32px;
    background-color: #999 !important;
    visibility: visible !important;
  }
}

.fields-collection-table-empty {
  text-align: center;
  display: table-cell;
  padding: 40px 0;
}

.fields-collection-table-header-group {
  display: table-header-group;
  .resizeable-header {
    height: 32px;
    max-height: 32px;
    position: relative;
    span {
      line-height: 32px;
      max-height: 32px;
      display: inline-block;
      position: absolute;
      bottom: 0;
      left: 10px;
      right: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      pointer-events: none;
    }
    .alias-editor-title {
      span {
        left: 32px;
      }
    }
  }
  div:not(.handle):not(.editor-button):not(.base-icon):not(.alias-editor-title) {
    display: table-cell;
    font-size: 10px;
    font-weight: 300;
    text-transform: uppercase;
    padding: 7px 10px !important;
    margin: 0;
    position: relative;
    white-space: nowrap;
    height: 32px;
    max-height: 32px;
  }
  .handle {
    width: 5px;
    border-right: 1px solid darken(@gray-lighter, 5%);
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: ew-resize !important;
    height: 27px;
    opacity: 0;
    &::before {
      content: '';
      position: absolute;
      top: 2px;
      left: 1px;
      background-color: @gray-lighter;
      width: 1px;
      height: 18px;
    }
    &::after {
      content: '';
      position: absolute;
      top: 2px;
      right: -4px;
      background-color: @gray-lighter;
      width: 1px;
      height: 18px;
    }
    &:hover {
      border-right: 1px solid darken(@gray-lighter, 15%);
      &::before,
      &::after {
        background-color: darken(@gray-lighter, 10%);
      }
    }
    &.active {
      border-right: 1px solid darken(@gray-lighter, 30%);
      opacity: 1;
      &::before,
      &::after {
        background-color: darken(@gray-lighter, 20%);
      }
    }
  }
  .resizeable-header {
    height: 32px;
    min-height: 32px;
    max-height: 32px;
    &.resize-disabled {
      span {
        transform: translateX(-4px);
        width: 32px;
        overflow: auto;
        text-overflow: clip;
      }
    }
  }
  &:hover {
    .handle {
      opacity: 1;
    }
  }
}

.fields-collection-row {
  .mat-mdc-cell {
    padding: 0 !important;
  }
  .fields-collection-editor {
    &.active {
      background-color: #fff;
    }
    &.readonly {
      position: relative;
      pointer-events: none;

      .field-picker {
        padding: 0;
      }

      .mat-mdc-select {
        opacity: 0.7;
        background-color: @gray-lighter;
        padding-left: 15px;
        padding-right: 5px;

        &::after {
          display: none;
        }
      }

      .mat-mdc-select-arrow-wrapper {
        display: none;
      }
    }
    &.required {
      .mat-mdc-select-arrow-wrapper {
        display: none;
      }
      .mat-mdc-select {
        &.mat-mdc-select-disabled {
          opacity: 1;
        }
        .mat-mdc-select-trigger {
          mat-select-trigger {
            span {
              &::before {
                content: '*';
                margin-right: 2px;
                color: #177af0;
              }
            }
          }
        }
      }
    }
    .tooltip {
      .tooltip-inner {
        max-width: 320px;
      }
    }
  }
  .fields-collection-row-index,
  .fields-collection-row-sort,
  .fields-collection-row-add,
  .fields-collection-row-checkbox,
  .fields-collection-row-remove {
    width: 32px;
    display: table-cell;
    transition: background-color 0.5s ease;
  }
  .fields-collection-row-index {
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    user-select: none;
  }
  .fields-collection-row-add,
  .fields-collection-row-sort,
  .fields-collection-row-remove {
    display: table-cell;
    height: 32px;
  }
  .fields-collection-row-sort {
    cursor: pointer;
    color: @gray-light;
    font-size: 12px;
    &:hover {
      color: @gray;
    }
  }
  .fields-collection-editors {
    flex: 1 auto;
    width: 100%; //min-width: 140px;
    .fields-collection-editors-transclude {
      display: flex;
      flex-direction: row;
      width: 100%;
      alias-editor,
      sort-direction-picker {
        flex: 1 auto;
      }
    }
  }
  &.ui-sortable-helper {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.175);
    .fields-collection-row-index,
    .fields-collection-row-sort,
    .fields-collection-row-add,
    .fields-collection-row-remove,
    .fields-collection-editor {
      border-bottom: 1px solid @gray-lighter;
    }
  } // &:nth-child(even) {
  //     background-color: #FFF;
  //     .fields-collection-row-index {
  //         //background-color: lighten(#EEE, 4%);
  //     }
  // }
  // &:nth-child(odd) {
  //     background-color: lighten(#EEE, 4%);
  // } // &:hover {
  // //     background-color: #FFF;
  // //     .fields-collection-row-index {
  // //         background-color: @gray;
  // //         color: #FFF;
  // //     }
  // // }
  &.active {
    background-color: lighten(@gray-light, 15%);
    z-index: 1;
    .fields-collection-row-index {
      background-color: @gray;
      color: #fff;
    }
  }
  &.error {
    .fields-collection-row-index {
      background-color: lighten(@brand-warning, 45%) !important;
      color: @brand-warning;
    }
  }
  &:hover {
    background-color: lighten(@gray-light, 15%);
  }
  &.search {
    background-color: lighten(@brand-primary, 50%);
    .fields-collection-row-index {
      background-color: lighten(@brand-primary, 30%);
      color: #fff;
    }
    &.active {
      background-color: lighten(@brand-primary, 40%) !important;
      .fields-collection-row-index {
        background-color: lighten(@brand-primary, 20%) !important;
        color: #fff;
      }
    }
  } // &.notify-remove {
  //     background-color: lighten(@brand-warning, 30%);
  //     .fields-collection-row-remove {
  //         background-color: @brand-warning;
  //         color: #FFF;
  //         i {
  //             color: #FFF;
  //         }
  //     }
  // }
  // &.notify-add {
  //     background-color: lighten(@brand-success, 35%);
  //     .fields-collection-row-add {
  //         background-color: @brand-success;
  //         color: #FFF;
  //         i {
  //             color: #FFF;
  //         }
  //     }
  // }
}

.fields-collection-footer {
  padding: 20px 0 0;
  display: flex;
  .fields-collection-pagination,
  .fields-collection-errors {
    flex: 1;
    width: 50%;
  }
  .fields-collection-errors {
  }
  .pagination {
    margin: 0 0;
    li {
      a {
        //padding: 3px 8px 2px;
        font-size: 12px;
      }
    }
  }
}
