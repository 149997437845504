@import './xplenty-bootstrap-variables';

.cdk-overlay-container {
  z-index: 99999 !important;

  .mat-mdc-tooltip {
    border: none;

    .mdc-tooltip__surface {
      position: relative;
      border: none;
      font-size: 14px;
      font-weight: 400;
      padding: 7px 12px;
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.175);
      overflow: visible;
      background: #081d34;
      border-radius: 8px;
    }

    &.wide {
      .mat-mdc-tooltip-surface {
        max-width: 80vw;
      }
    }

    &.wide-400 {
      .mat-mdc-tooltip-surface {
        max-width: 400px;
      }
    }

    &.multiline-tooltip {
      white-space: pre-line;

      .mat-mdc-tooltip-surface {
        white-space: pre-line;
      }
    }

    &::before {
      position: absolute;
      content: '';
      display: inline-block;
      background: #081d34;
      clip-path: polygon(50% 0, 0 50%, 50% 100%);
      width: 15px;
      height: 15px;
      bottom: -8px;
      left: 50%;
      transform: translateX(-50%) rotate(-90deg);
    }

    &.after,
    &.right {
      &::before {
        left: -8px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &.before,
    &.left {
      &::before {
        top: calc(50% - 6px);
        left: calc(100% - 8px);
        transform: rotate(-180deg);
      }
    }

    &.below {
      &::before {
        top: -8px;
        left: 50%;
        transform: translateX(-50%) rotate(90deg);
      }
    }

    &.hide-arrow {
      &::before {
        content: none;
      }
    }
  }
}

.mat-mdc-tooltip.error {
  background-color: lighten(@brand-warning, 45%);
  border-color: lighten(@brand-warning, 45%);
  color: @brand-warning;
  font-weight: 400;
  padding: 5px 10px;
  font-family: proxima-nova, sans-serif;

  &::before {
    background-color: lighten(@brand-warning, 45%);
    border-bottom-color: lighten(@brand-warning, 45%);
    border-top-color: lighten(@brand-warning, 45%);
  }

  .mdc-tooltip__surface {
    background: transparent;
    color: @brand-warning;
    box-shadow: none;
  }
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: #000000de;
}

.autocomplete-description {
  margin-left: 15px;
  font-size: 12px;
  font-weight: 700;
  opacity: 0.5;
  pointer-events: none;
}

.mat-mdc-select-placeholder {
  opacity: 0.5;
}

.mat-mdc-option {
  font-size: 14px;
  height: auto;
  line-height: 20px;
  padding: 5px 10px;
  color: #3f3f3f;
  font-family: proxima-nova, sans-serif;
  letter-spacing: normal;
  min-height: 42px !important;
  --mat-option-selected-state-label-text-color: #000000;

  .mat-mdc-option-pseudo-checkbox {
    display: none;
  }

  &.mat-mdc-option-active {
    background: rgba(0, 0, 0, 0.12) !important;
    color: #000000de !important;
  }

  .mdc-list-item__primary-text {
    font-size: 14px !important;
  }
}

.mdc-tab__content {
  letter-spacing: normal !important;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: @brand-primary !important;
}

.mdc-tab__ripple:before,
.mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-link .mat-ripple-element {
  background-color: @brand-primary;
}

.mdc-tab-indicator__content {
  border-color: #e1e1e1 !important;
  opacity: 1 !important;
}

.mdc-tab-indicator--active .mdc-tab-indicator__content {
  border-color: @brand-primary !important;
}

.ordered-field-picker-panel {
  .mat-mdc-option {
    .mat-mdc-option-pseudo-checkbox {
      display: block;

      &.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full {
        background: #000000;
      }
    }
  }
}

.mat-mdc-select {
  height: 100%;
  min-height: 32px;
  display: flex !important;
  align-items: center;
  padding-left: 10px;
  letter-spacing: 0 !important;

  .mat-mdc-select-trigger {
    width: 100%;
    height: auto;
  }

  &.dead,
  &.disabled {
    .mat-mdc-select-value {
      color: lightgrey;
      pointer-events: none;
      cursor: not-allowed;
    }
  }
}

.mat-mdc-select-disabled {
  opacity: 0.4;
}

.mat-mdc-select-value {
  padding: 0;
  color: #3f3f3f;
  font-size: 14px;
  font-family: proxima-nova, sans-serif;

  .small-field & {
    padding: 0;
    display: flex;
    flex-flow: row;
    max-width: initial;
  }
}

.mat-mdc-text-field-wrapper {
  padding-right: 5px !important;
}

.mat-mdc-select-arrow-wrapper {
  .small-field & {
    padding: 0;
  }

  .mat-mdc-select-arrow {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid;
    margin: 0 4px;

    svg {
      display: none !important;
    }
  }
}

.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  border: none;
}

.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
  border: none;
  border-bottom: 1px solid black;
  border-radius: 0 !important;
  height: 90%;
}

.mat-mdc-select-option {
  padding: 0 10px;
  color: #3f3f3f;
  font-size: 14px;
  font-family: proxima-nova, sans-serif;
}

.mat-mdc-select-panel-wrap {
  position: relative;
}

.mat-mdc-select-panel-wrap {
  .mat-select-search-input {
    border: none;
  }
  .mat-select-search-clear {
    display: none;
  }
}

ngx-mat-select-search .mat-mdc-icon-button.mat-mdc-button-base {
  display: none !important;
}

.mat-mdc-option {
  &.contains-mat-select-search {
    position: sticky !important;
    top: 0;
    width: 100%;
    z-index: 10;
  }

  &.dead {
    color: lightgrey;
    text-decoration: line-through;
  }
  &.unavailable {
    cursor: not-allowed;
    pointer-events: none;

    .mdc-list-item__primary-text {
      color: lightgrey !important;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
  &.disabled {
    display: none;
  }
  &.deprecated {
    color: lightgrey;
  }

  .option-description {
    display: block;
    font-size: 12px;
    opacity: 0.6;
    line-height: 14px;
    margin-bottom: 10px;
  }

  .top-margin {
    margin-top: 10px;
  }
}

.mat-mdc-chip-input {
  border: none;
  height: 38px;
}

.mat-mdc-chip-remove {
  display: flex;
  align-items: center;
  width: 12px !important;
}

xp-chips {
  .mat-mdc-chip-list {
    .mat-mdc-chip {
      border-radius: 4px;
      padding: 5px;
    }
  }
}

.mat-mdc-select-panel {
  max-height: 500px !important;
  padding: 0 !important;

  @media (max-height: 960px) {
    max-height: 400px !important;
  }

  @media (max-height: 600px) {
    max-height: 300px !important;
  }
}

.connection-select-box {
  height: 700px !important;
  max-height: 700px !important;
}

.mat-mdc-pseudo-checkbox-checked {
  background: @gray-base;
}

.mat-mdc-snack-bar-container {
  border: none;
  font-size: 14px;
  max-width: 90vw !important;
  min-height: 0 !important;

  .mdc-snackbar__surface {
    border-left: 2px solid;
    min-width: 444px;
    position: relative;
  }

  &.no-shadow {
    box-shadow: none;
  }

  &.success {
    .mdc-snackbar__surface {
      .message,
      .alert-heading,
      .fa {
        color: #0a6037;
      }
      border-color: @brand-success-green;
      background-color: @brand-success-green-background;
    }
  }

  &.info {
    .mdc-snackbar__surface {
      .message,
      .fa {
        color: @brand-neutral;
      }

      border-color: @brand-neutral;
      background-color: @brand-neutral-background;
    }
  }

  &.warning {
    .mdc-snackbar__surface {
      .message,
      .alert-heading,
      .fa {
        color: @brand-warning;
      }
      border-color: @brand-warning;
      background-color: @brand-warning-background;
    }
  }

  &.danger {
    .mdc-snackbar__surface {
      .message,
      .fa {
        color: @brand-danger;
      }

      border-color: @brand-danger;
      background-color: @brand-danger-background;
    }
  }

  .notify-template {
    white-space: nowrap;
    display: flex;
    flex-flow: column;
    padding-right: 20px;

    .close {
      position: absolute;
      right: 10px;
      top: 10px;
    }

    .alert-heading {
      font-size: 16px;
      font-weight: 600;
      margin: 0;
    }

    .message-container {
      margin-right: 20px;
      white-space: break-spaces;
    }

    .fa-stack {
      margin-right: 0;
    }
  }

  .message {
    margin: 0;
  }
}

.mat-mdc-dialog-container {
  padding: 0 !important;
  position: relative;
  overflow: hidden !important;

  .dialog-close {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 7px;

    &:before {
      background: transparent;
      border-radius: 3px;
      color: #bbb;
      content: '\00D7';
      font-size: 26px;
      font-weight: 400;
      height: 30px;
      line-height: 26px;
      position: absolute;
      right: 3px;
      text-align: center;
      top: 3px;
      width: 30px;
    }

    &:hover {
      &:before {
        color: #777;
      }
    }
  }
}

.new-dialog {
  .modal-dialog {
    margin-right: 0;
  }
}

.mat-mdc-menu-content {
  padding: 0 !important;

  .mat-mdc-menu-item {
    &.top-separator {
      border-top: 1px solid #e5e5e5;
    }
    &.bottom-separator {
      border-bottom: 1px solid #e5e5e5;
    }
  }
}

.mat-mdc-menu-panel {
  min-height: 48px !important;
  overflow: visible !important;
}

mat-menu {
  display: none;
}

mat-slide-toggle.mat-mdc-slide-toggle {
  &.mat-mdc-checked {
    .mat-mdc-slide-toggle-bar {
      background-color: rgba(10, 179, 229, 0.5);
    }
    .mat-mdc-slide-toggle-thumb-container {
      .mat-mdc-slide-toggle-thumb {
        background-color: @brand-primary;
      }
    }
  }
}

.select-picker.mat-mdc-select-panel {
  .mat-mdc-option {
    height: auto !important;
    border-top: 1px solid #00000036;
    padding: 10px 20px;

    &:nth-child(2) {
      border-top: none;
    }
  }
}

.select-picker-mat {
  border: 1px solid #cccccc;
  border-radius: 4px;
  margin-right: 10px;
  width: auto !important;
  background: #ffffff;

  .mat-mdc-select-arrow-wrapper {
    .mat-mdc-select-arrow {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid;
      margin: 0 5px;
    }
  }
}

.mat-mdc-menu-panel.mat-mdc-menu-panel {
  border: 1px solid #f2f4f7 !important;
  box-shadow:
    0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03) !important;
  border-radius: 8px !important;
}

.mat-mdc-menu-item {
  display: flex !important;
  align-items: center;
  font-size: 13px;
  font-family:
    Roboto,
    Helvetica Neue,
    sans-serif !important;

  &[hidden] {
    display: none !important;
  }

  img {
    width: 22px;
    height: 22px;
    margin-right: 5px;
  }
  .mat-menu-item-text,
  .mdc-list-item__primary-text,
  .mat-mdc-menu-item-text {
    font-weight: 400;
    padding: 2px 0;
    font-size: 13px !important;
    font-family:
      Roboto,
      Helvetica Neue,
      sans-serif !important;
    letter-spacing: normal !important;
    color: #000000 !important;
  }

  span,
  a {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
  }
}

.mat-mdc-select-panel.select-picker {
  margin-top: -15px !important;
  margin-left: -10px !important;
}

.mat-mdc-select-panel {
  min-width: 312px;

  &.role-picker-panel {
    min-width: 350px;
  }
}

.mat-select-search-input {
  margin-top: 15px;

  &.mat-select-search-hidden {
    margin-top: -4px;
  }

  @media (max-width: @screen-sm) {
    max-width: calc(100vw - 70px);
  }
}

.mat-select-search-no-entries-found {
  padding: 20px 10px 14px !important;
  opacity: 0.38;
}

.assisted-search-date-input {
  display: block;
  visibility: hidden;
}

.shortcuts-dialog {
  width: 500px;
}

.mat-mdc-slider {
  width: 100%;
}

.mat-mdc-accent {
  .mat-mdc-slider-thumb,
  .mat-mdc-slider-track-fill,
  .mat-mdc-slider-thumb-label {
    background-color: @brand-primary !important;
  }

  .mat-mdc-slider-focus-ring {
    background-color: @brand-primary !important;
    opacity: 0.2 !important;
  }
}

.cdk-drag {
  z-index: 99999999 !important;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drop-list-dragging .mat-mdc-row:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.variables-editor-sortable.cdk-drop-list-dragging variables-editor-row:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.full-dropdown-menu {
  margin-top: -40px;
  margin-left: 5px;
}

xp-chips {
  height: auto !important;
  padding: 10px !important;
}

.mat-mdc-table {
  width: 100%;
  border-radius: 10px;

  &.resizing {
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: col-resize;
  }

  thead {
    background: #f9fafb !important;
    border-bottom: 1px solid #eceef0;

    .mat-mdc-header-cell {
      overflow: visible;
      border: none !important;
    }
  }
}

.mat-mdc-cell {
  padding: 0 5px !important;
  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.small-field {
    width: 30px;
    text-align: center;

    &.gray {
      background-color: #f9fafb;
    }
  }
}
.mat-mdc-header-cell {
  position: relative;
  padding: 0 5px !important;
  font-size: 11px !important;
  font-weight: bold !important;

  &:not(:last-child) {
    .resize-holder {
      cursor: col-resize;
      height: 100%;
      position: absolute;
      right: -1px;
      top: 0;
      z-index: 1;
      width: 5px;
      border-right: 1px solid @gray-lighter;
      opacity: 1;

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 2px;
        background-color: @gray-lighter;
        width: 1px;
        height: 28px;
      }

      &:before {
        left: 1px;
      }
      &:after {
        right: -4px;
      }
    }
  }
}

.mat-mdc-cell,
.mat-mdc-header-cell {
  border-right: 1px solid rgba(0, 0, 0, 0.12);

  &:last-child {
    border-right: none;
  }

  &:not(:nth-child(1)) {
    padding: 0 10px;
  }
}

.mat-mdc-row {
  height: 33px !important;
}

.mat-mdc-header-row {
  height: 28px !important;
}

.constant-width {
  width: 32px;
  padding: 0 !important;
  text-align: center;
}

.mat-mdc-paginator {
  &[hidden] {
    display: none;
  }
}

.cdk-drag-preview.mat-mdc-row {
  display: table !important;

  .editor-button {
    height: 20px;
  }
}

.mat-mdc-tab-label {
  width: 100% !important;
}

.mat-mdc-tab-group.mat-mdc-primary .mat-mdc-ink-bar,
.mat-mdc-tab-nav-bar.mat-mdc-primary .mat-mdc-ink-bar {
  background-color: @brand-primary !important;
}

.mat-mdc-slider {
  min-width: 200px !important;

  .mdc-slider__track--inactive {
    border-color: @brand-primary !important;
  }

  .mdc-slider__track--active_fill {
    border-color: @brand-primary !important;
  }

  .mdc-slider__thumb-knob {
    background-color: @brand-primary !important;
    border-color: @brand-primary !important;
  }
}

.mdc-switch__icons {
  display: none !important;
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
  //background:
}

html .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-track-color: @brand-primary;
  --mdc-switch-selected-focus-state-layer-color: @brand-primary;
  --mdc-switch-selected-handle-color: #fff;
  --mdc-switch-selected-hover-state-layer-color: @brand-primary;
  --mdc-switch-selected-pressed-state-layer-color: @brand-primary;
  --mdc-switch-selected-focus-handle-color: #fff;
  --mdc-switch-selected-hover-handle-color: #fff;
  --mdc-switch-selected-pressed-handle-color: #fff;
  --mdc-switch-selected-focus-track-color: @brand-primary;
  --mdc-switch-selected-hover-track-color: @brand-primary;
  --mdc-switch-selected-pressed-track-color: @brand-primary;
}

html .mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #fff;
  --mdc-switch-disabled-unselected-handle-color: #fff;
  --mdc-switch-disabled-selected-track-color: #eaecf0;
  --mdc-switch-disabled-unselected-track-color: #eaecf0;
  --mdc-switch-unselected-focus-state-layer-color: #fff;
  --mdc-switch-unselected-pressed-state-layer-color: #fff;
  --mdc-switch-unselected-hover-state-layer-color: #fff;
  --mdc-switch-unselected-focus-track-color: #eaecf0;
  --mdc-switch-unselected-hover-track-color: #eaecf0;
  --mdc-switch-unselected-pressed-track-color: #eaecf0;
  --mdc-switch-unselected-track-color: #eaecf0;
  --mdc-switch-selected-track-color: @brand-primary;
  --mdc-switch-unselected-focus-handle-color: #fff;
  --mdc-switch-unselected-hover-handle-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #fff;
  --mdc-switch-handle-surface-color: #fff;
  --mdc-switch-unselected-handle-color: #fff;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-track-height: 20px;
  --mdc-switch-handle-width: 16px;
  --mdc-switch-handle-height: 16px;
  --mdc-switch-track-shape: 12px;
  --mdc-switch-track-width: 36px;
}

.mdc-switch--selected .mdc-switch__handle-track {
  left: -2px !important;
}

.mdc-switch__handle-track {
  left: 2px !important;
}

.mat-mdc-slide-toggle .mdc-form-field label {
  margin-bottom: 0;
  margin-left: 10px;
}

.mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: @brand-primary;
  --mdc-slider-focus-handle-color: @brand-primary;
  --mdc-slider-hover-handle-color: @brand-primary;
  --mdc-slider-active-track-color: @brand-primary;
  --mdc-slider-inactive-track-color: @brand-primary;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: @brand-primary;
  --mat-mdc-slider-ripple-color: @brand-primary;
  --mat-mdc-slider-hover-ripple-color: rgba(23, 122, 240, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(23, 122, 240, 0.2);
}

.mat-mdc-menu-item .list-item-multiple {
  display: block;
  line-height: 15px;
}
