.ngdialog {
  &.ngdialog-theme-default {
    .ngdialog-content {
      z-index: 1 !important;
      font-family: @font-family-sans-serif;
    }
  }
}

.dialog {
  display: flex;
  flex-flow: column;
  padding: 0;
  margin-bottom: 0;

  .link {
    color: #177af0;
    text-decoration: none;
    cursor: pointer;
  }

  .dialog-btn-container {
    display: flex;
    flex-flow: row;
    padding: 30px;
    justify-content: center;
  }

  .clustercat-holding {
    .clustercat-holding-hand {
      position: absolute;
      animation: handsMove 1s linear infinite alternate;
      &.right {
        top: -23px;
        right: -40px;
      }
      &.left {
        top: -23px;
        left: 60px;
      }
    }
    .clustercat-holding-head {
      position: absolute;
      top: -131px;
      left: 50%;
      transform: translatex(-50%);
    }
  }

  .dialog-text {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 0 20px;
    align-items: center;

    .danger-icon {
      margin-top: 10px;
    }
  }

  &.confirm {
    background-color: @modal-content-bg;
    h3 {
      color: @gray-base;
    }
    p {
      color: @gray;
      margin-bottom: 30px;
    }
    .dialog-button {
      font-size: 14px;
      font-weight: 600;
      width: auto;
      padding: 10px 16px;
      height: auto;
      border-radius: 8px;
      margin: 0 5px;

      &.btn-default {
        background-color: @modal-content-bg;
        width: auto;
        border: 1px solid #bdc5cf;
        padding: 10px 16px;
        line-height: inherit;
      }
      &.btn-danger {
        color: #ffffff;
        background: @brand-danger;
        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);

        &:hover {
          background: darken(@brand-danger, 20%);
        }
      }
      &.btn-warning {
        background-color: @brand-warning;
        color: #ffffff;

        &:hover {
          background-color: darken(@brand-warning, 5%);
          color: #ffffff;
        }
      }
      &.btn-info {
        background-color: @brand-primary;
        color: @brand-info;

        &:hover {
          background-color: darken(@brand-primary, 5%);
          color: @brand-info;
        }
      }
      &.btn-success {
        background-color: @modal-content-bg;
        color: @brand-success;
      }
      &:hover {
        background-color: lighten(@gray-lighter, 5%);
        border-color: @gray-lighter;
      }
    }
  }
}

@keyframes handsMove {
  0% {
    transform: translate(3px, 0);
  }
  100% {
    transform: translate(-3px, 0);
  }
}

.ngdialog.ngdialog-theme-default .ngdialog-content {
  padding: 0;
}

.ai-transformations-dialog {
  .component-container {
    margin: 10px 0;
    padding: 10px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    transition: all 0.3s ease-in-out;
    width: 800px;
    cursor: pointer;
    border-radius: 8px;

    &:hover {
      background: #dedede;
    }

    .component-title {
      font-size: 18px;
      color: #3f3f3f;
      text-align: left;
      font-weight: bold;
    }

    .component-description {
      font-size: 14px;
      color: #3f3f3f;
      text-align: center;

      .component-description-example {
        margin-top: 5px;
        display: block;
      }
    }

    .component-button {
      justify-self: flex-end;
      margin-top: 10px;
      font-size: 14px;
      padding: 10px;
    }
  }
}
