// LIBS
@import '../../../node_modules/bootstrap/less/bootstrap';
@import '../../../node_modules/font-awesome/less/font-awesome';
@import '../../../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '../../../node_modules/xterm/css/xterm.css';
@import './fonts';
@import './xplenty-bootstrap-variables';
@import './lib/angular-json-explorer';
@import './lib/loading-bar';
@import './lib/codemirror';
@import '../../../node_modules/codemirror/addon/hint/show-hint.css';
@import './lib/show-hint';
@import './lib/dialog';
@import './lib/tern';
@import './lib/chartist';
@import './lib/perfect-scrollbar';
@import './lib/loaders';
@import './lib/splitter';
@import './lib/checkbox';

// COMMON
@import './xplenty-icons';

// MODULES
@import './xplenty-designer/designer';

@import './components/component-previewer';

// DIRECTIVES
@import './directives/nav';
@import './directives/navbar';
@import './directives/sidebar';
@import './directives/user-menu';
@import './directives/accounts-menu';
@import './directives/modal';
@import './directives/modal-panel';
@import './directives/account-picker';
@import './directives/account-list';
@import './directives/page-header';
@import './directives/cluster';
@import './directives/connection';
@import './directives/variables-editor';
@import './directives/package-variables-editor';
@import './directives/variable-form-control';
@import './directives/name-description-editor';
@import './directives/forms';
@import './directives/error-list';
@import './directives/expressions';
@import './directives/expression-editor';
@import './directives/plans';
@import './directives/checkbox';
@import './directives/account-usage';
@import './directives/cluster-icon';
@import './directives/components-list';
@import './directives/new-cluster-form';
@import './directives/forms/schedule-form';
@import './directives/forms/time-picker';
@import './directives/forms/workspace-form';
@import './directives/schema-editor';
@import './directives/wizard';
@import './directives/terminal';
@import './directives/connections/connections-list';
@import './directives/connections/connection-form-shopify';
@import './directives/date-range';
@import './directives/headers-editor';
@import './directives/select-editor';
@import './directives/sort-editor';
@import './directives/url-editor';
@import './directives/job-error';
@import './directives/packages-selector';
@import './directives/errors-viewer';
@import './directives/data-preview';
@import './directives/help-menu';
@import './directives/table-view';
@import './directives/job-view/job-view';
@import './directives/alert-box';
@import './directives/errors-box';
@import './directives/schema-importer';
@import './directives/steps';
@import './directives/select-picker';
@import './directives/note-editor';
@import './directives/accounts-campaigns-selector';
@import './directives/input-checkbox';
@import './directives/assisted-search';
@import './directives/package';
@import './directives/packages-picker';
@import './directives/component';
@import './directives/components-order-editor';
@import './directives/members-invitation';
@import './directives/package-json-import-form';
@import './directives/x-console';

// DIRECTIVES: generic-list
@import './directives/generic-list/generic-list';
@import './directives/generic-list/generic-list-item';
@import './directives/generic-list/generic-list-modal';
@import './directives/generic-list/generic-list-item-snippet';
@import './directives/generic-list/package-list-item';
@import './directives/generic-list/workspace-list-item';
@import './directives/generic-list/connection-list-item';
@import './directives/generic-list/cluster-list-item';
@import './directives/generic-list/job-list-item';
@import './directives/generic-list/schedule-list-item';
@import './directives/generic-list/member-list-item';
@import './directives/generic-list/account-list-item';
@import './directives/generic-list/hook-list-item';
@import './directives/generic-list/key-list-item';

// FIELDS COLLECTION
@import './directives/fields-collection/base-input';
@import './directives/fields-collection/editor-button';
@import './directives/fields-collection/checkbox-editor';
@import './directives/fields-collection/alias-editor';
@import './directives/fields-collection/alias-editor-title';
@import './directives/fields-collection/field-picker';
@import './directives/fields-collection/ordered-fields-picker';
@import './directives/fields-collection/fields-collection';
@import './directives/fields-collection/fields-collection-errors-box';
@import './directives/fields-collection/window-arguments-editor';

// ONBOARDING
@import './directives/onboarding/onboarding-toolbar';

// FORMS
@import './directives/forms/hook-form';
@import './directives/forms/schedule-form';
@import './directives/forms/job-form';
@import './directives/forms/package-version-form';
@import './directives/forms/package-form';
@import './directives/forms/connection-form';
@import './directives/forms/member-form';
@import './directives/forms/key-form';
@import './directives/forms/account-form';
@import './directives/forms/packages-picker-form';

// DIALOGS
@import './dialogs/dialogs';

// OVERRIDES
@import './overrides/buttons';
@import './overrides/forms';
@import './overrides/dropdowns';

// OFFLINE
@import './overrides/offline-theme-default';
@import './overrides/offline-language-english';

// COMPONENT FORMS
@import './directives/component-forms/_base-editor';
@import './directives/component-forms/_component-editor';
@import './directives/component-forms/adwords-source-editor';
@import './directives/component-forms/component-form';
@import './directives/component-forms/filter-variables-editor';
@import './directives/component-forms/notify-editor';
@import './directives/component-forms/cross-join-editor';
@import './directives/component-forms/run-package-editor';
@import './directives/component-forms/bing-ads-accounts';
@import './directives/component-forms/limit-editor';
@import './directives/component-forms/sample-editor';
@import './directives/component-forms/window-editor';
@import './directives/component-forms/join-editor';
@import './directives/component-forms/union-editor';
@import './directives/component-forms/filter-editor';
@import './directives/component-forms/assert-editor';
@import './directives/component-forms/aggregate-editor';
@import './directives/component-forms/cube-editor';
@import './directives/component-forms/facebook-editor';
@import './directives/component-forms/file-names-pattern';
@import './directives/component-forms/execute-sql-editor';
@import './directives/component-forms/component-name-editor';
@import './directives/component-forms/bing-ads-source-editor';
@import './directives/component-forms/salesforce-source-editor';
@import './directives/component-forms/database-source-editor';
@import './directives/component-forms/rest-api-authentication';
@import './directives/component-forms/rest-api-source-editor';
@import './directives/component-forms/schema-mapping-salesforce';

// DESTINATIONS
@import './directives/component-forms/destination-editor';
@import './directives/component-forms/amazon-redshift-destination-editor';
@import './directives/component-forms/database-destination-editor';
@import './directives/component-forms/database-advanced-options-destination';

// PAGES
@import './main-wrapper';
@import './pages/app';
@import './pages/account';
@import './pages/account-settings';
@import './pages/billing';
@import './pages/plans-table';
@import './pages/settings';
@import './pages/clusters';
@import './pages/auth';
@import './pages/thank-you';
@import './pages/package';

@import './common';
@import './list-group';
@import './form-errors';
@import './shortcuts';

@import './material-overrides';
@import './animations';
