.component-name-editor-new {
  position: relative;
  padding: 10px 35px 15px;
  margin-left: 30px;
  width: 50%;
  z-index: 0;

  input {
    width: 100%;
    border: 1px solid transparent;
    border-bottom: 1px dashed @gray-light;
    .input-text-style;
    &.ng-invalid {
      border-bottom-color: @brand-warning !important;
    }
    &:focus {
      border-bottom-color: @brand-primary;
    }
    &::-webkit-input-placeholder {
      color: @gray-light;
    }
    &::-moz-placeholder {
      color: @gray-light;
    }
    &:-ms-input-placeholder {
      color: @gray-light;
    }
    &:-moz-placeholder {
      color: @gray-light;
    }
  }
  .component-name-editor-icon-info {
    position: absolute;
    bottom: 24px;
    right: 16px;
    color: @gray-lighter;
    font-size: 16px;
  }
  .component-name-editor-icon-edit {
    display: none;
    position: absolute;
    top: 20px;
    left: -30px;
    color: @gray-lighter;
    font-size: 24px;
  }
  &:hover {
    .component-name-editor-icon-edit {
      display: block;
    }
  }
  .form-group {
    margin-bottom: 0 !important;
    &.ng-invalid {
      .input-error-message {
        display: block !important;
      }
    }
  }
  .input-error-message {
    position: absolute;
    bottom: -20px;
    font-size: 12px;
  }
  .tooltip {
    max-width: 400px !important;
    .tooltip-inner {
      width: 400px !important;
      max-width: 400px !important;
      font-size: 13px !important;
      font-style: italic;
      text-align: left;
    }
  }
}

.input-text-style {
  font-size: 24px;
  font-weight: 600;
}
