.run-package-editor {
  //padding: 50px 70px;
  .packages-selector {
    //  margin-bottom: 30px;
  }
}

.form-group-options {
  padding-left: 5px;
  .radio {
    padding-bottom: 5px;
  }
}
