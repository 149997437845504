@media (max-width: @screen-xs-max) {
  .modal(10px, 10px, 20px);
  .modal {
    width: 100%;
  }
}

@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
  .modal(10px, 20px, 30px);
}

@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
  .modal(10px, 30px, 40px);
}

@media (min-width: @screen-lg-min) {
  .modal(23px, 30px, 40px);
}

.modal(@modal-padding-top: 20px, @modal-padding-left: 20px, @modal-title-size: 20px) {
  .modal {
    top: 0;
    right: 0;
    left: auto;
    overflow: visible !important;
    min-width: 1100px;

    @media (max-width: @screen-sm) {
      min-width: auto;
    }

    .modal-btn-close {
      position: absolute;
      top: 0;
      left: 0;
      border-right: 1px solid @gray-light;
      cursor: pointer;
      width: 45px;
      height: 100%;
      background-color: #ffffff;
      display: none;
      z-index: 10;

      @media (max-width: @screen-sm) {
        display: block;
        top: 0;
        left: 0;
        width: 100vw;
        height: 44px;
        z-index: 100;
        border-bottom: 1px solid #cccccc;
      }

      &:hover {
        background-color: @gray-lighter;
        span {
          color: @gray-base;
        }
        &:after {
          color: @gray-base;
        }
      }
      span {
        position: absolute;
        display: block;
        transform: translateY(-50%) rotate(-90deg);
        transform-origin: center;
        color: lighten(@gray-base, 50%);
        width: 100%;
        white-space: nowrap;
        top: 50%;
        font-size: 16px;

        @media (max-width: @screen-sm) {
          position: inherit;
          transform: none;
          text-align: center;
          top: 0;
          line-height: 44px;
        }
      }
      &:after {
        font-family: 'FontAwesome', sans-serif;
        background: transparent;
        content: '\f053';
        font-size: 20px;
        font-weight: 400;
        text-align: center;
        color: @gray-light;
        display: block;
        margin: 0;
        width: 100%;
        height: 52px;
        line-height: 40px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        @media (max-width: @screen-sm) {
          transform: none;
          position: inherit;
          display: flex;
          justify-content: flex-end;
          padding: 0 20px;
          left: initial;
          content: 'x';
        }
      }
    }
    .modal-btn-back {
      position: absolute;
      top: 0;
      left: -44px;
      border-right: 1px solid @gray-light;
      z-index: 2000;
      cursor: pointer;
      width: 45px;
      height: 100%;
      background-color: darken(@gray, 10%);
      display: none;

      &.new {
        display: block;

        @media (max-width: @screen-sm) {
          top: 0;
          left: 0;
          width: 100vw;
          height: 44px;
          z-index: 100;
          border-bottom: 1px solid #cccccc;
          display: flex;
          align-items: center;
          justify-content: center;

          &:after {
            transform: translateX(-70%);
          }

          span {
            position: initial;
            transform: none;
            text-align: center;
          }
        }
      }
      &.active {
        display: block;
      }
      &:hover {
        background-color: darken(@gray, 5%);
        span {
          color: #fff;
        }
        &:after {
          color: #fff;
        }
      }
      span {
        position: absolute;
        display: block;
        transform: translateY(-50%) rotate(-90deg);
        transform-origin: center;
        color: lighten(@gray-base, 50%);
        width: 100%;
        white-space: nowrap;
        top: 50%;
      }
      &:after {
        font-family: 'FontAwesome', sans-serif;
        background: transparent;
        content: '\f104';
        font-size: 26px;
        font-weight: 400;
        text-align: center;
        color: @gray-light;
        display: block;
        margin: 0;
        width: 100%;
        height: 52px;
        line-height: 52px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &.in {
      .modal-btn-close {
        display: block;
      }
    }
    .modal-dialog {
      margin: 0 auto;
      background-color: @gray-lighter;
      height: 100%;
      transition-duration: 150ms !important;

      &.thin {
        width: 720px;
      }
      &.very-thin {
        width: 520px;
      }

      .modal-body {
        position: relative;
        padding-bottom: 120px !important;
        height: 100%;
        z-index: 11;
        background: white;
        margin-left: 45px;
        overflow: visible;

        @media (max-width: @screen-sm) {
          margin-left: 0;
        }

        &.no-padding {
          padding: 0 !important;

          @media (max-width: @screen-sm) {
            padding-top: 44px !important;
          }
        }
        .generic-list {
          padding-right: 20px;
        }
      }
      .modal-footer {
        position: absolute;
        bottom: 0;
        display: flex;
        background: #f5f7f9;
        text-align: left;
        z-index: 1;
        height: 80px;
        width: 100%;
        margin-left: 0;
        padding-left: 20px;

        @media (max-width: @screen-sm) {
          padding: 10px;
          display: flex;
          flex-flow: column;
          height: auto !important;
          min-height: auto !important;
          justify-content: center;
          align-items: center;
          width: 100%;
          margin-left: 0;
        }

        .buttons-container {
          display: flex;
          flex-flow: row;

          @media (max-width: @screen-sm) {
            margin: 10px 0;
          }
        }

        &.has-description {
          &.has-icon {
            .modal-icon {
              transform: translate(-50%, -50%);
              left: 40px;
              top: 50%;
            }
            .modal-title {
              font-size: 18px;
              font-weight: 300;
              margin-top: 5px;
              &::after {
                font-family: 'FontAwesome', sans-serif;
                content: '\f040';
                color: @gray-light;
                display: block;
                font-size: 18px;
                position: absolute;
                right: 0;
                top: 0;
              }
            }
          }
        }

        .btn + .btn {
          margin-bottom: 0;
          margin-left: 15px;
        }

        .modal-icon {
          position: absolute;
          top: 30px;
          left: 30px;
        }
        .modal-title-container {
          margin-left: 10px;
          white-space: nowrap;
          flex: 1;
          display: flex;
          flex-direction: row;
          justify-content: left;
          transform: translateY(8px);

          @media (max-width: @screen-sm) {
            margin-bottom: 10px;
            margin-left: 0;
            margin-top: 10px;
            transform: none;
          }

          svg {
            @media (max-width: @screen-sm) {
              margin-right: 10px;
            }
          }
        }
        .modal-title {
          font-weight: 600;
          padding: 0;
          color: #3d4651;
          width: 50%;
          margin: 0 0 0 20px;

          @media (max-width: @screen-sm) {
            width: auto;
            font-size: 20px;
            line-height: 35px;
            margin: 0;
            white-space: break-spaces;
            text-align: center;
          }
        }
        .modal-description {
          font-size: 12px;
          font-weight: 300;
          margin: 0;
          color: darken(@gray-light, 15%);
        }
        .btn {
          &.modal-btn-extra {
            margin-right: 20px;
            i {
              margin-right: 10px;
            }
          }
        }
        &.has-icon {
          .modal-title {
            transform: translate(54px, -4px);
            border-bottom: 1px solid @gray-light;
            width: 50%;
            outline: none;
          }
          .modal-description {
            transform: translate(54px, -4px);
          }
        }
      }
    }
    opacity: 1 !important;
    &.fade .modal-dialog {
      opacity: 1 !important;
      .transition-transform(150ms cubic-bezier(0, 0, 1, 0));
      transform: translate3d(100%, 0, 0);
    }
    &.in .modal-dialog {
      opacity: 1 !important;
      .transition-transform(2s cubic-bezier(0.19,0.77,0.43,0.98));
      transform: translate3d(0, 0, 0);
    }
  }
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.4);
  transition: all 150ms ease;
  &.absolute {
    position: absolute;
  }
  &.animate {
    opacity: 1 !important;
  }
}

.modal-form-container {
  margin: 30px 40px;
  padding: 30px;
  border-radius: 10px;
  background-color: #f5f7f9;

  @media (max-width: @screen-sm) {
    margin: 0;
    padding: 15px;
  }
}
