.select-picker-editable {
  display: flex;
  flex-direction: row;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }

  .select-picker,
  .select-picker-selected-item-wrapper {
    flex: 1 auto;
    position: relative;
    cursor: pointer;
    &:after {
      content: ' ';
      display: block;
      position: absolute;
      right: 17px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: #3f3f3f transparent transparent transparent;
      margin-top: auto;
      margin-bottom: auto;
      top: 0;
      bottom: 0;
    }
    &.has-right-margin {
      margin-right: 10px;
    }
    &.unauthorized {
      cursor: pointer !important;
      opacity: 1 !important;
      .select-picker-buttons {
        button {
          .btn-create-new {
            display: none;
          }
        }
      }
    }
  }
  .select-picker-selected-item {
    border: 1px solid @gray-light;
    border-radius: 4px;
    min-height: 70px;
    padding: 0 35px 5px 7px;
  }
  .select-picker-editable-buttons {
    position: relative;
    flex: 0 auto;
    button {
      max-height: 41px;
      min-height: 41px;
      i {
        font-size: 10px;
        margin-right: 3px;
      }
    }
    .dropdown-menu {
      top: 50px !important;
      left: auto !important;
      right: 0 !important;
      padding: 10px !important;
      li {
        a {
          display: flex;
          padding: 5px !important;
          img {
            width: 22px;
            height: 22px;
            margin-right: 10px;
          }
          span {
            font-weight: 400;
            padding: 2px 0;
          }
        }
      }
    }
  }
  .select-picker-buttons {
    flex: 0 auto;
    min-width: 100px;
    i {
      margin-left: 3px;
      font-size: 11px;
    }
  }
  svg {
    color: #cccccc !important;
    stroke: #000000 !important;
    fill: transparent;
  }
  .select-picker-container {
    flex: 1 auto;
  }
}

select-picker-connection,
.select-picker-package,
.select-picker-cluster,
.select-picker-workspace,
.select-picker-component {
  min-height: 70px;
  display: flex;
  align-items: center;
}

.select-picker {
  position: relative;
  .loader {
    z-index: 2;
    background-color: #fff;
    transform: scale(0.6);
  }
}

.select-picker-item-container {
  padding: 10px 0 0 10px;
}

.select-picker-connection,
.select-picker-package,
.select-picker-cluster,
.select-picker-workspace,
.select-picker-component {
  .select-picker-item {
    padding-bottom: 10px;
    .select-picker-header {
      display: flex;
      .select-picker-icon {
        flex: 0 auto;
        width: 30px;
        height: 30px;
        padding: 3px 0;
        img {
          width: 22px;
          height: 22px;
          opacity: 1;
        }
        svg {
          width: 30px;
          height: 30px;
        }
      }
      .select-picker-name {
        padding: 4px 0 4px 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 900;
        font-size: 16px;
        max-width: 500px;
        line-height: 24px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: flex-start;

        span,
        div {
          &.select-picker-name-title {
            display: flex;
            height: auto;
            align-items: center;
            line-height: 18px;
            font-size: 15px;
            font-weight: 600;
            color: @text-color;
            margin-bottom: 5px;
            white-space: pre-wrap;
            word-wrap: break-word;
            .label-default {
              background-color: #fff;
              border: 1px solid @gray-lighter;
              color: @gray;
              font-weight: 300;
              display: inline-block;
              transform: translate(0px, -3px);
            }
            .btn {
              margin-left: 5px;
              padding: 3px 10px;
              font-weight: 400;
              font-size: 12px;
              text-transform: uppercase;
              &.btn-black {
                font-weight: 600;
              }
            }
          }
          &.select-picker-name-description {
            display: block;
            font-size: 13px;
            font-weight: 400;
            line-height: 16px;
            color: @gray;
            white-space: pre-wrap;
            word-wrap: break-word;
          }
        }
      }
      .select-picker-buttons {
        padding-top: 1px;
        margin-left: 10px;
        .btn {
          padding: 3px 10px;
          font-weight: 400;
          font-size: 12px;
          text-transform: uppercase;
          &.btn-black {
            font-weight: 600;
          }
        }
      }
    }
    .select-picker-body {
      padding-left: 40px;
      padding-top: 5px;
      span {
        color: @gray;
        font-size: 13px;
        font-weight: 300;
        line-height: 15px;
        &.small {
          display: block;
        }
      }
    }
  }
  &:last-child {
    border-bottom: 0 none;
  }
  &.active {
    svg {
      color: #cccccc !important;
      fill: #8c8c8c !important;
    }
  }
}
